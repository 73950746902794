var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { COMPONENT_TYPES } from "./component-types";
var getReactComponent = function (componentType, element, props) { return __awaiter(void 0, void 0, void 0, function () {
    var ReactDOM, React;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, import(
                /* webpackChunkName: "react-dom" */ "react-dom")];
            case 1:
                ReactDOM = _a.sent();
                return [4 /*yield*/, import(/* webpackChunkName: "react" */ "react")];
            case 2:
                React = _a.sent();
                switch (componentType) {
                    case COMPONENT_TYPES.FavoriteToggle:
                        return [2 /*return*/, import(
                            /* webpackChunkName: "favorite-toggle" */
                            "@react-components/ui/FavoriteToggle/FavoriteToggle").then(function (_a) {
                                var FavoriteToggle = _a.FavoriteToggle;
                                ReactDOM.render(_jsx(FavoriteToggle, __assign({}, props), void 0), element);
                            })];
                    case COMPONENT_TYPES.FavoriteCollection:
                        return [2 /*return*/, import(
                            /* webpackChunkName: "favorite-collection" */
                            "@react-components/modules/FavoriteCollection/FavoriteCollection").then(function (_a) {
                                var FavoriteCollection = _a.FavoriteCollection;
                                ReactDOM.render(_jsx(FavoriteCollection, __assign({}, props), void 0), element);
                            })];
                    case COMPONENT_TYPES.Search:
                        return [2 /*return*/, import(
                            /* webpackChunkName: "search" */
                            "@react-components/modules/Search/Search").then(function (Search) {
                                ReactDOM.render(_jsx(Search.default, __assign({}, props), void 0), element);
                            })];
                    case COMPONENT_TYPES.TableOfContent:
                        return [2 /*return*/, import(
                            /* webpackChunkName: "table-of-content" */
                            "@react-components/modules/TableOfContent/TableOfContent").then(function (_a) {
                                var TableOfContent = _a.TableOfContent;
                                ReactDOM.render(_jsx(TableOfContent, __assign({}, __assign({}, props)), void 0), element);
                            })];
                    case COMPONENT_TYPES.PageTypeList:
                        return [2 /*return*/, import(
                            /* webpackChunkName: "page-type-list" */
                            "@react-components/modules/PageTypeList/PageTypeList").then(function (_a) {
                                var PageTypeList = _a.PageTypeList;
                                ReactDOM.render(_jsx(PageTypeList, __assign({}, __assign({}, props)), void 0), element);
                            })];
                    case COMPONENT_TYPES.UmbracoImage:
                        return [2 /*return*/, import(
                            /* webpackChunkName: "umbraco-image" */
                            "@react-components/modules/UmbracoImage/UmbracoImage").then(function (_a) {
                                var UmbracoImage = _a.UmbracoImage;
                                ReactDOM.render(_jsx(UmbracoImage, __assign({}, __assign({}, props)), void 0), element);
                            })];
                    case COMPONENT_TYPES.EventList:
                        return [2 /*return*/, import(
                            /* webpackChunkName: "event-list" */
                            "@react-components/modules/EventList/EventList").then(function (_a) {
                                var EventList = _a.EventList;
                                ReactDOM.render(_jsx(EventList, __assign({}, __assign({}, props)), void 0), element);
                            })];
                    case COMPONENT_TYPES.FindEducation:
                        return [2 /*return*/, import(
                            /* webpackChunkName: "find-education" */
                            "@react-components/modules/FindEducation/FindEducation").then(function (_a) {
                                var FindEducation = _a.FindEducation;
                                ReactDOM.render(_jsx(FindEducation, __assign({}, __assign({}, props)), void 0), element);
                            })];
                    case COMPONENT_TYPES.EResourceList:
                        return [2 /*return*/, import(
                            /* webpackChunkName: "e-resource-list" */
                            "@react-components/modules/EResourceList/EResourceList").then(function (_a) {
                                var EResourceList = _a.EResourceList;
                                ReactDOM.render(_jsx(EResourceList, __assign({}, __assign({}, props)), void 0), element);
                            })];
                    case COMPONENT_TYPES.EvuTabsList:
                        return [2 /*return*/, import(
                            /* webpackChunkName: "evu-tabs-list" */
                            "@react-components/modules/EvuTabsList/EvuTabsList").then(function (_a) {
                                var EvuTabsList = _a.EvuTabsList;
                                ReactDOM.render(_jsx(EvuTabsList, __assign({}, __assign({}, props)), void 0), element);
                            })];
                    case COMPONENT_TYPES.EvuChildrenList:
                        return [2 /*return*/, import(
                            /* webpackChunkName: "evu-children-list" */
                            "@react-components/modules/EvuChildrenList/EvuChildrenList").then(function (_a) {
                                var EvuChildrenList = _a.EvuChildrenList;
                                ReactDOM.render(_jsx(EvuChildrenList, __assign({}, __assign({}, props)), void 0), element);
                            })];
                    case COMPONENT_TYPES.OverviewListWithFilters:
                        return [2 /*return*/, import(
                            /* webpackChunkName: "overview-list-with-filters" */
                            "@react-components/modules/OverviewListWithFilters/OverviewListWithFilters").then(function (_a) {
                                var OverviewListWithFilters = _a.OverviewListWithFilters;
                                ReactDOM.render(_jsx(OverviewListWithFilters, __assign({}, __assign({}, props)), void 0), element);
                            })];
                    case COMPONENT_TYPES.HeaderSearch:
                        return [2 /*return*/, import(
                            /* webpackChunkName: "header-search" */
                            "@react-components/modules/HeaderSearch/HeaderSearch").then(function (HeaderSearch) {
                                ReactDOM.render(_jsx(HeaderSearch.default, __assign({}, props), void 0), element);
                            })];
                    case COMPONENT_TYPES.employeeSearch:
                        return [2 /*return*/, import(
                            /* webpackChunkName: "employee-search" */
                            "@react-components/modules/EmployeeSearch/EmployeeSearch").then(function (EmployeeSearch) {
                                ReactDOM.render(_jsx(EmployeeSearch.default, __assign({}, props), void 0), element);
                            })];
                    case COMPONENT_TYPES.contactCard:
                        return [2 /*return*/, import(
                            /* webpackChunkName: "contact-card" */
                            "@react-components/modules/ContactCard/ContactCard").then(function (_a) {
                                var ContactCard = _a.ContactCard;
                                ReactDOM.render(_jsx(ContactCard, __assign({}, __assign({}, props)), void 0), element);
                            })];
                    case COMPONENT_TYPES.RegistrationsModal:
                        return [2 /*return*/, import(
                            /* webpackChunkName: "registrations-modal" */
                            "@react-components/modules/RegistrationsModal/RegistrationsModal").then(function (_a) {
                                var RegistrationsModal = _a.RegistrationsModal;
                                ReactDOM.render(_jsx(RegistrationsModal, __assign({}, __assign({}, props)), void 0), element);
                            })];
                    case COMPONENT_TYPES.HeroSearch:
                        return [2 /*return*/, import(
                            /* webpackChunkName: "hero-search" */
                            "@react-components/modules/HeroSearch/HeroSearch").then(function (HeroSearch) {
                                ReactDOM.render(_jsx(HeroSearch.default, __assign({}, __assign({}, props)), void 0), element);
                            })];
                }
                return [2 /*return*/];
        }
    });
}); };
export { getReactComponent };
