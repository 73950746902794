import { reactBootstrapper } from "@react-common/bootstrapper/react-bootstrapper";
import { COMPONENT_TYPES } from "@react-common/bootstrapper/component-types";
reactBootstrapper({
    favoriteToggle: COMPONENT_TYPES.FavoriteToggle,
    favoriteCollection: COMPONENT_TYPES.FavoriteCollection,
    search: COMPONENT_TYPES.Search,
    tableOfContent: COMPONENT_TYPES.TableOfContent,
    pageTypeList: COMPONENT_TYPES.PageTypeList,
    umbracoImage: COMPONENT_TYPES.UmbracoImage,
    eventList: COMPONENT_TYPES.EventList,
    findEducation: COMPONENT_TYPES.FindEducation,
    headerSearch: COMPONENT_TYPES.HeaderSearch,
    employeeSearch: COMPONENT_TYPES.employeeSearch,
    contactCard: COMPONENT_TYPES.contactCard,
    evuTabsList: COMPONENT_TYPES.EvuTabsList,
    evuChildrenList: COMPONENT_TYPES.EvuChildrenList,
    overviewListWithFilters: COMPONENT_TYPES.OverviewListWithFilters,
    registrationsModal: COMPONENT_TYPES.RegistrationsModal,
    eResourceList: COMPONENT_TYPES.EResourceList,
    heroSearch: COMPONENT_TYPES.HeroSearch,
});
